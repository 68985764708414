import React, { useEffect, useRef } from "react";
import { makeStyles, Box } from "@material-ui/core";
import NotificationView from "../../views/Notification";
const useStyles = makeStyles({
  root: {
    width: 450,
    // height: 600,
    borderRadius: 10,
    // maxHeight: "80vh",
    backgroundColor: "white",
    position: "fixed",
    right: 70,
    top: 60,
    zIndex: 100,
  },
});
type Props = {
  open: boolean;
  setOpen: React.Dispatch<boolean | any>;
};

function index({ open, setOpen }: Props) {
  const classes = useStyles();
  const divRef = useRef(null);
  // useEffect(() => {
  //   const clickEvent = (e: any) => {
  //     if (document.getElementById("notificationModal").contains(e.target)) {
  //       // Clicked in box
  //       console.log("clicked in box");
  //     } else {
  //       console.log("clicked outside box");
  //       setOpen(false);
  //     }
  //   };
  //   if (open) {
  //     window.addEventListener("click", clickEvent);
  //   }
  //   return () => window.removeEventListener("click", clickEvent);
  // }, [open]);
  return (
    <Box className={classes.root} boxShadow={5} id="notificationModal" onClick={() => setOpen((prev) => !prev)}>
      <NotificationView />
    </Box>
  );
}

export default index;
