import React from "react";
import type { FC, ReactNode } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";
import Header from "./Header";

interface ApiLayoutProps {
  children?: ReactNode;
}

const useStyles = makeStyles(theme => ({
  root: {
    // backgroundColor: theme.palette.background.default,
    width: "100%",
    display: "flex",
    flex: "1",
    flexDirection: "column",
  },
  mainSection: {
    marginTop: 80,
    maxWidth: 1200,
    minHeight: "calc(100vh - 155px)",
    width: "100vw",
    display: "flex",
    justifyContent: "center",
    margin: "0 auto",
    [theme.breakpoints.down("md")]: {
      width: "calc(100vw - 50px)",
    },
  },
}));

const ApiLayout: FC<ApiLayoutProps> = ({ children }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Header />
      <section className={classes.mainSection}>{children}</section>
    </div>
  );
};

ApiLayout.propTypes = {
  children: PropTypes.node,
};

export default ApiLayout;
