import React, { useEffect, useState, ReactElement } from "react";
import type { FC } from "react";
import { makeStyles, Button } from "@material-ui/core";
import {
  Badge,
  Grow,
  Paper,
  Popper,
  MenuItem,
  MenuList,
  ClickAwayListener,
  Drawer,
  Box,
  List,
  ListItemText,
  Hidden,
} from "@mui/material";
import {
  AccountCircle as AccountCircleIcon,
  Menu as MenuIcon,
} from "@material-ui/icons";
import NotificationsNoneOutlinedIcon from "@material-ui/icons/NotificationsNoneOutlined";
import { useParams, useHistory, useLocation } from "react-router-dom";
import * as Routes from "../../routes";
import Logo from "../../../public/static/logo-inline.svg";
import { db } from "../../lib/firebase";
import * as sessionStorageConst from "../../views/api_view/sessionStorageConst";
import { useDispatch, useSelector } from "../../store";
import useAuth from "../../hooks/useAuth";
import { firestore } from "../../lib/firebase";
import axios from "axios";
import sendApproveEmail from "../../utils/sendApproveEmail";
// import { Mailer } from "nodemailer-react";
const useStyles = makeStyles(() => ({
  stickyContainer: {
    position: "fixed",
    width: "100%",
    backgroundColor: "white",
    zIndex: 1000,
    borderBottom: "1px solid #E9EEF4",
  },
  headerBox: {
    height: 80,
    width: "100%",
    maxWidth: 1200,
    padding: "0px 12px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    boxSizing: "border-box",
    lineHeight: 0,
    margin: "0 auto",
  },
  headerLogo: {
    cursor: "pointer",
  },
  headerNavi: {
    marginLeft: 30,
  },
  headerMenu: {
    marginLeft: "auto",
  },
  naviBtn: {
    padding: 13,
    verticalAlign: "middle",
    cursor: "pointer",
  },
  logoImage: {
    width: 200,
  },
  createBtn: {},
}));

interface HeaderProps {}

const Header: FC<HeaderProps> = () => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const { user } = useAuth();
  const { id: clientId, logo } = useSelector((state) => state.client);
  const [contactEmail, setContactEmail] = useState("");

  // 승인 요청
  function requestApprove() {
    if (user) {
      const userRef = db.collection("User").doc(user.id);
      userRef
        .get()
        .then(async (doc) => {
          if (doc.exists) {
            const userData = doc.data();
            if (!userData?.cloudData?.[clientId]) {
              const clientData = (
                await firestore().collection("Client").doc(clientId).get()
              ).data();
              let clientEmail = clientData
                ? clientData.email
                  ? clientData.email
                  : "no-reply@onthewall.io"
                : "no-reply@onthewall.io";
              const isConfirmed = confirm(
                `전시를 생성하기 위해서는 관리자의 승인이 필요합니다. 승인을 요청하시겠습니까?`
              );
              if (isConfirmed) {
                userRef
                  .update({
                    [`cloudData.${clientId}`]: {
                      approved: "wait",
                      isBlocked: false,
                      requestTime: firestore.Timestamp.now(),
                    },
                  })
                  .then(() => {
                    sendApproveEmail(userData, clientId, clientEmail);
                    alert("신청되었습니다.");
                  })
                  .catch((error) => {
                    console.error("Error updating document: ", error);
                  });
              }
            } else {
              history.push(`/${clientId}${Routes.cloudManageExhibition}`);
            }
          }
        })
        .catch((error) => {
          console.log("Error getting document:", error);
        });
    }
  }

  const toExhibitionBuildPage = () => {
    if (!user) {
      history.push(`/${clientId}${Routes.cloudLogin}`);
      return;
    } else {
      requestApprove();
    }
  };

  return (
    <header>
      <div className={classes.stickyContainer}>
        <div className={classes.headerBox}>
          <div
            className={classes.headerLogo}
            onClick={() => {
              history.push(`/${clientId}`);
            }}
          >
            {logo ? (
              <img src={logo} className={classes.logoImage} />
            ) : (
              "로고위치"
            )}
          </div>
          {location.pathname !== `/${clientId}${Routes.cloudSignUp2}` && (
            <div className={classes.createBtn}>
              <Button onClick={toExhibitionBuildPage} variant="contained">
                가상전시 스튜디오
              </Button>
            </div>
          )}
        </div>
      </div>
    </header>
  );
};

export default Header;
