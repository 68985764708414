import React, { Fragment, lazy, Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import AuthGuard from './components/AuthGuard';
import GuestGuard from './components/GuestGuard';
import PhoneGuard from './components/PhoneGuard';
import NoPhoneGuard from './components/NoPhoneGuard';
import LoadingScreen from './components/LoadingScreen';
import MainLayout from './layouts/MainLayout';
import SubLayout from './layouts/MainLayout/SubLayout';
import ApiLayout from './layouts/ApiLayout';
import CloudLayout from './layouts/CloudLayout';
import CloudMainLayout from './layouts/CloudMainLayout';
import ApiAuth from './components/ApiAuth';
import PhoneGuardCloud from './components/PhoneGuardCloud';
import AdminGuardCloud from './components/AdminGuardCloud';

type Routes = {
	exact?: boolean;
	path?: string | string[];
	guard?: any[];
	layout?: any;
	component?: any;
	routes?: Routes;
	routeType: 'Platform' | 'CloudMain' | 'CloudEdit';
}[];

export const renderRoutes = (routes: Routes): JSX.Element => {
	return (
		<Suspense fallback={<LoadingScreen />}>
			<Switch>
				{routes.map((route, i) => {
					const Guard1 = route.guard[0] || Fragment;
					const Guard2 = route.guard[1] || Fragment;
					const Layout = route.layout || Fragment;
					const Component = route.component;

					return (
						<Route
							key={i}
							path={route.path}
							exact={route.exact}
							render={props => (
								<Guard1>
									<Guard2>
										<Layout>
											{route.routes ? renderRoutes(route.routes) : <Component {...props} />}
										</Layout>
									</Guard2>
								</Guard1>
							)}
						/>
					);
				})}
			</Switch>
		</Suspense>
	);
};

/**
 * 걸어본 플랫폼
 */
export const root = '/'; // 메인 페이지
export const login = '/login'; // 로그인 페이지
export const signUp = '/join'; // 회원가입 step1
export const signUp2 = '/join2'; // 회원가입 step2
export const comments = '/comments'; // 댓글 관리
export const spaces = '/spaces'; // 이용 가능한 공간
export const mypage = '/mypage'; // 마이 페이지
export const editMypage = '/mypage/edit'; // 내 정보 수정
export const changePhoneNum = '/mypage/changephonenum'; // 전화번호 수정
export const changePassword = '/mypage/changepassword'; // 비밀번호 수정
export const manageComment = '/manage/comments'; // 내 댓글 관리
export const manageExhibition = '/exhibition'; // 전시 관리
export const registerExhibition = '/exhibition/register'; // 새 전시 만들기
export const editExhibition = '/edit/:id'; // 전시 생성 끝(작품설치)
export const tutorials = '/tutorials'; // 동영상 튜토리얼 보기
export const plan = '/plan'; // 플랜
export const pay = '/pay'; // 결제
export const payComplete = '/pay/complete'; // 결제완료
export const notification = '/notification'; // 알림
export const subscription = '/subscription'; // 요금제 및 결제 (구독)
export const rootJP = '/jp'; // 요금제 및 결제 (구독)

/**
 * api
 */
export const apiRoot = '/api';
export const apiManageExhibition = '/api/exhibition'; // 전시 관리
export const apiRegisterExhibition = '/api/exhibition/register'; // 새 전시 만들기
export const apiManageComment = '/api/manage/comments'; // 내 댓글 관리

/**
 * 클라우드 서비스
 * Nested routes로 /앞에 clientId가 들어갈 것.
 */
export const cloudMain = '/'; // 메인 페이지
export const cloudSpecialExhibition = '/special/:id'; // 기획전시
export const cloudLogin = '/login'; // 로그인 페이지
export const cloudSignUp = '/join'; // 회원가입 step1
export const cloudSignUp2 = '/join2'; // 회원가입 step2
export const cloudManageExhibition = '/exhibition'; // 전시 관리
export const cloudSpace = '/spaces'; // 공간 관리
export const cloudTutorials = '/tutorials'; // 공간 관리
export const cloudRegisterExhibition = '/exhibition/register'; // 새 전시 만들기
export const cloudEditExhibition = '/edit/:id'; // 전시 생성 끝(작품설치)
export const cloudManageComment = '/manage/comments'; // 내 댓글 관리
export const cloudMypage = '/mypage'; // 마이페이지
export const cloudChangePhoneNum = '/mypage/changephonenum'; // 전화번호 수정
export const cloudChangePassword = '/mypage/changepassword'; // 비밀번호 수정
export const cloudAsk = '/ask'; // 문의하기
export const cloudAdminSpecialExhibitionEdit = '/admin/special-exhibition/edit/:id'; // 기획 전시 수정
export const cloudAdminSpecialExhibitionCreate = '/admin/special-exhibition/create'; // 기획 전시 만들기
export const cloudAdminSpecialExhibition = '/admin/special-exhibition'; // 기획 전시 관리 페이지 >> 홈 섹션 관리로 변경

export const cloudAdminGrantUser = '/admin/grant-user'; // 유저 승인 페이지 >> 가입 승인으로 변경
export const cloudAdminManageUser = '/admin/user'; // 유저 관리 페이지
export const cloudAdminUserInfo = '/admin/user/:userId'; // 유저 정보 페이지
export const cloudAdminGrantExhibition = '/admin/grant-exhibition'; // 전시 승인 페이지 >> 출판 승인으로 변경
export const cloudAdminManageExhibition = '/admin/exhibition'; // 전시 관리 페이지
export const cloudAdminManageBanner = '/admin/banner'; // 배너 관리 페이지
export const cloudAdminEditExhibition = '/admin/edit/:id'; // 작품설치

const routes: Routes = [
	/**
	 * 걸어본 플랫폼
	 */
	{
		exact: true,
		guard: [],
		path: editExhibition,
		component: lazy(() => import('./views/Edit')),
		routeType: 'Platform',
	},
	{
		exact: true,
		guard: [PhoneGuard],
		path: rootJP,
		component: lazy(() => import('./views/HomeJP')),
		// component: () => <Redirect to='/exhibition' />,
		layout: MainLayout,
		routeType: 'Platform',
	},
	{
		exact: true,
		guard: [PhoneGuard],
		path: root,
		component: lazy(() => import('./views/Home')),
		// component: () => <Redirect to='/exhibition' />,
		layout: MainLayout,
		routeType: 'Platform',
	},
	{
		exact: true,
		guard: [GuestGuard],
		path: login,
		component: lazy(() => import('./views/auth/Login')),
		layout: MainLayout,
		routeType: 'Platform',
	},
	{
		exact: true,
		guard: [GuestGuard],
		path: '/global-login',
		component: lazy(() => import('./views/auth/loginWithoutPhone')),
		layout: MainLayout,
		routeType: 'Platform',
	},
	{
		exact: true,
		guard: [GuestGuard],
		path: '/global-join',
		component: lazy(() => import('./views/auth/joinWithoutPhone')),
		layout: MainLayout,
		routeType: 'Platform',
	},
	{
		exact: true,
		guard: [],
		path: '/global-join2',
		component: lazy(() => import('./views/auth/join2WithoutPhone')),
		layout: MainLayout,
		routeType: 'Platform',
	},
	{
		exact: true,
		guard: [GuestGuard],
		path: signUp,
		component: lazy(() => import('./views/auth/Join')),
		layout: MainLayout,
		routeType: 'Platform',
	},
	{
		exact: true,
		guard: [AuthGuard, NoPhoneGuard],
		path: signUp2,
		component: lazy(() => import('./views/auth/Join2')),
		layout: MainLayout,
		routeType: 'Platform',
	},
	{
		exact: true,
		guard: [],
		path: spaces,
		component: lazy(() => import('./views/Spaces')),
		layout: MainLayout,
		routeType: 'Platform',
	},
	{
		exact: true,
		guard: [AuthGuard, PhoneGuard],
		path: mypage,
		component: lazy(() => import('./views/MyPage')),
		layout: SubLayout,
		routeType: 'Platform',
	},
	{
		exact: true,
		guard: [AuthGuard, PhoneGuard],
		path: editMypage,
		component: lazy(() => import('./views/MyPage/Edit')),
		layout: MainLayout,
		routeType: 'Platform',
	},
	{
		exact: true,
		guard: [AuthGuard, PhoneGuard],
		path: manageComment,
		component: lazy(() => import('./views/Manage/Comments')),
		layout: MainLayout,
		routeType: 'Platform',
	},
	{
		exact: true,
		guard: [AuthGuard, PhoneGuard],
		path: changePhoneNum,
		component: lazy(() => import('./views/MyPage/ChangePhoneNum')),
		layout: MainLayout,
		routeType: 'Platform',
	},
	{
		exact: true,
		guard: [AuthGuard, PhoneGuard],
		path: changePassword,
		component: lazy(() => import('./views/MyPage/ChangePassword')),
		layout: MainLayout,
		routeType: 'Platform',
	},
	{
		exact: true,
		guard: [AuthGuard, PhoneGuard],
		path: registerExhibition,
		component: lazy(() => import('./views/Exhibition/Register/index')),
		layout: MainLayout,
		routeType: 'Platform',
	},
	{
		exact: true,
		guard: [AuthGuard, PhoneGuard],
		path: manageExhibition,
		component: lazy(() => import('./views/Exhibition/index')),
		layout: MainLayout,
		routeType: 'Platform',
	},
	{
		exact: true,
		guard: [],
		path: plan,
		component: lazy(() => import('./views/Plan')),
		layout: MainLayout,
		routeType: 'Platform',
	},
	{
		exact: true,
		guard: [],
		path: payComplete,
		component: lazy(() => import('./views/Pay/PayComplete')),
		layout: MainLayout,
		routeType: 'Platform',
	},
	{
		exact: true,
		guard: [],
		path: `${pay}/:id`,
		component: lazy(() => import('./views/Pay/PayConfig')),
		layout: SubLayout,
		routeType: 'Platform',
	},
	{
		exact: true,
		guard: [],
		path: `${subscription}/:tab`,
		component: lazy(() => import('./views/Pay/Subscription')),
		layout: SubLayout,
		routeType: 'Platform',
	},

	{
		exact: true,
		guard: [],
		path: tutorials,
		component: lazy(() => import('./views/Tutorials')),
		layout: MainLayout,
		routeType: 'Platform',
	},
	{
		exact: true,
		guard: [],
		path: '/terms/use_condition',
		component: lazy(() => import('./views/UseCondition')),
		routeType: 'Platform',
	},
	{
		exact: true,
		guard: [],
		path: '/terms/pipp',
		component: lazy(() => import('./views/Pipp')),
		routeType: 'Platform',
	},
	{
		exact: true,
		guard: [AuthGuard, PhoneGuard],
		path: notification,
		component: lazy(() => import('./views/Notification')),
		layout: MainLayout,
		routeType: 'Platform',
	},

	/**
	 * api
	 */
	{
		exact: true,
		guard: [],
		path: apiRoot,
		component: lazy(() => import('./views/api_view')),
		layout: MainLayout,
		routeType: 'Platform',
	},
	{
		exact: true,
		guard: [ApiAuth],
		path: apiManageExhibition,
		component: lazy(() => import('./views/api_view/Exhibitions')),
		layout: ApiLayout,
		routeType: 'Platform',
	},
	{
		exact: true,
		guard: [ApiAuth],
		path: apiRegisterExhibition,
		component: lazy(() => import('./views/api_view/Register/index')),
		layout: ApiLayout,
		routeType: 'Platform',
	},
	{
		exact: true,
		guard: [ApiAuth],
		path: '/api/edit/:id',
		component: lazy(() => import('./views/api_view/Edit')),
		routeType: 'Platform',
	},
	{
		exact: true,
		guard: [ApiAuth],
		path: apiManageComment,
		component: lazy(() => import('./views/api_view/ManageComments')),
		layout: ApiLayout,
		routeType: 'Platform',
	},
	{
		exact: true,
		guard: [],
		path: '/super-admin',
		component: lazy(() => import('./views/SuperAdmin')),
		layout: MainLayout,
		routeType: 'Platform',
	},
	{
		exact: false,
		guard: [],
		path: '/not-found',
		component: lazy(() => import('./views/errors/NotFound')),
		routeType: 'Platform',
	},
	/* NOTE: (2023-10-19) 걸어본 플랫폼에서 클라우드 라우팅 주석 처리. */
	// {
	//   exact: false,
	//   guard: [],
	//   path: '/:clientId',
	//   component: lazy(() => import('./views/cloud_view/CloudApp')),
	//   routeType: 'CloudMain',
	// },
	{
		exact: false,
		guard: [],
		path: '*',
		component: lazy(() => import('./views/errors/NotFound')),
		routeType: 'Platform',
	},
];
export const cloudRoutes: Routes = [
	// 클라우드 관리자
	{
		exact: true,
		guard: [AdminGuardCloud],
		path: cloudAdminGrantUser,
		component: lazy(() => import('./views/cloud_view/admin/GrantUser/GrantUser')),
		layout: CloudLayout,
		routeType: 'CloudEdit',
	},
	{
		exact: true,
		guard: [AdminGuardCloud],
		path: cloudAdminManageUser,
		component: lazy(() => import('./views/cloud_view/admin/ManageUser/ManageUser')),
		layout: CloudLayout,
		routeType: 'CloudEdit',
	},
	{
		exact: true,
		guard: [AdminGuardCloud],
		path: cloudAdminUserInfo,
		component: lazy(() => import('./views/cloud_view/admin/UserInfo')),
		layout: CloudLayout,
		routeType: 'CloudEdit',
	},
	{
		exact: true,
		guard: [AdminGuardCloud],
		path: cloudAdminGrantExhibition,
		component: lazy(() => import('./views/cloud_view/admin/GrantExhibition/GrantExhibition')),
		layout: CloudLayout,
		routeType: 'CloudEdit',
	},
	{
		exact: true,
		guard: [AdminGuardCloud],
		path: cloudAdminManageExhibition,
		component: lazy(() => import('./views/cloud_view/admin/ManageExhibition/ManageExhibition')),
		layout: CloudLayout,
		routeType: 'CloudEdit',
	},
	{
		exact: true,
		guard: [AdminGuardCloud],
		path: cloudAdminManageBanner,
		component: lazy(() => import('./views/cloud_view/admin/ManageBanner/ManageBanner')),
		layout: CloudLayout,
		routeType: 'CloudEdit',
	},
	{
		exact: true,
		guard: [AdminGuardCloud],
		path: cloudAdminEditExhibition,
		component: lazy(() => import('./views/cloud_view/admin/Edit')),
		routeType: 'CloudEdit',
	},
	{
		exact: true,
		guard: [AdminGuardCloud],
		path: cloudAdminSpecialExhibitionEdit,
		component: lazy(() => import('./views/cloud_view/admin/SpecialExhibition/Edit')),
		routeType: 'CloudEdit',
		layout: CloudLayout,
	},
	{
		exact: true,
		guard: [AdminGuardCloud],
		path: cloudAdminSpecialExhibition,
		component: lazy(() => import('./views/cloud_view/admin/SpecialExhibition')),
		routeType: 'CloudEdit',
		layout: CloudLayout,
	},
	{
		exact: true,
		guard: [AdminGuardCloud],
		path: cloudAdminSpecialExhibitionCreate,
		component: lazy(() => import('./views/cloud_view/admin/SpecialExhibition/Create')),
		routeType: 'CloudEdit',
		layout: CloudLayout,
	},
	/**
	 * 클라우드 서비스
	 */
	{
		exact: true,
		guard: [PhoneGuardCloud],
		path: cloudMain,
		layout: CloudMainLayout,
		component: lazy(() => import('./views/cloud_view/Main')),
		routeType: 'CloudMain',
	},
	{
		exact: true,
		guard: [PhoneGuardCloud],
		path: cloudSpecialExhibition,
		component: lazy(() => import('./views/cloud_view/SpecialExhibition')),
		layout: CloudMainLayout,
		routeType: 'CloudMain',
	},
	{
		exact: true,
		guard: [],
		path: cloudLogin,
		component: lazy(() => import('./views/cloud_view/auth/Login')),
		layout: CloudMainLayout,
		routeType: 'CloudMain',
	},
	{
		exact: true,
		guard: [],
		path: cloudSignUp,
		component: lazy(() => import('./views/cloud_view/auth/join')),
		layout: CloudMainLayout,

		routeType: 'CloudEdit',
	},
	{
		exact: true,
		guard: [],
		path: cloudSignUp2,
		component: lazy(() => import('./views/cloud_view/auth/join2')),
		layout: CloudMainLayout,
		routeType: 'CloudEdit',
	},
	{
		exact: true,
		guard: [],
		path: cloudManageExhibition,
		component: lazy(() => import('./views/cloud_view/manageExhibition/ManageExhibition')),
		layout: CloudLayout,
		routeType: 'CloudEdit',
	},
	{
		exact: true,
		guard: [],
		path: cloudRegisterExhibition,
		component: lazy(() => import('./views/cloud_view/manageExhibition/register/index')),
		layout: CloudLayout,
		routeType: 'CloudEdit',
	},
	{
		exact: true,
		guard: [],
		path: cloudSpace,
		component: lazy(() => import('./views/cloud_view/Spaces')),
		layout: CloudLayout,
		routeType: 'CloudEdit',
	},
	{
		exact: true,
		guard: [],
		path: cloudTutorials,
		component: lazy(() => import('./views/cloud_view/Tutorials')),
		layout: CloudLayout,
		routeType: 'CloudEdit',
	},
	{
		exact: true,
		guard: [],
		path: cloudEditExhibition,
		component: lazy(() => import('./views/cloud_view/manageExhibition/Edit')),
		routeType: 'CloudEdit',
	},
	{
		exact: true,
		guard: [],
		path: cloudManageComment,
		component: lazy(() => import('./views/cloud_view/manageExhibition/ManageComments')),
		layout: CloudLayout,
		routeType: 'CloudEdit',
	},
	{
		exact: true,
		guard: [AuthGuard],
		path: cloudMypage,
		component: lazy(() => import('./views/cloud_view/mypage/Index')),
		layout: CloudLayout,
		routeType: 'CloudEdit',
	},
	{
		exact: true,
		guard: [],
		path: cloudChangePhoneNum,
		component: lazy(() => import('./views/cloud_view/mypage/changePhoneNum')),
		layout: CloudLayout,
		routeType: 'CloudEdit',
	},
	{
		exact: true,
		guard: [],
		path: cloudChangePassword,
		component: lazy(() => import('./views/cloud_view/mypage/ChangePassword')),
		layout: CloudLayout,
		routeType: 'CloudEdit',
	},
	{
		exact: true,
		guard: [],
		path: cloudAsk,
		component: lazy(() => import('./views/cloud_view/Ask')),
		layout: CloudLayout,
		routeType: 'CloudEdit',
	},
];

export default routes;
