import React, { forwardRef, useEffect, useState, useCallback } from "react";
import type { HTMLProps, ReactNode } from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import track from "../utils/analytics";
import { useSelector } from "../store";
import { db, firestore } from "../lib/firebase";

interface PageProps extends HTMLProps<HTMLDivElement> {
  children?: ReactNode;
  title?: string;
}

const Page = forwardRef<HTMLDivElement, PageProps>(
  ({ children, title = "", ...rest }, ref) => {
    const location = useLocation();
    const { id: clientId } = useSelector((state) => state.client);
    const [clientTitle, setClientTitle] = useState("");
    const [faviconURL, setFaviconURL] = useState("");

    useEffect(() => {
      if (clientId) {
        firestore()
          .collection("Client")
          .doc(clientId)
          .get()
          .then((result) => {
            setClientTitle(result.data().title);
            if (result.data().faviconUrl)
              setFaviconURL(result.data().faviconUrl);
          });
      }
    }, []);

    const sendPageViewEvent = useCallback(() => {
      track.pageview({
        page_path: location.pathname,
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      sendPageViewEvent();
    }, [sendPageViewEvent]);

    return (
      <div ref={ref as any} {...rest}>
        <Helmet>
          {clientId ? (
            faviconURL ? (
              <link rel="shortcut icon" href={faviconURL} />
            ) : (
              <link
                rel="shortcut icon"
                href="data:image/x-icon"
                type="image/x-icon"
              ></link>
            )
          ) : (
            <link rel="icon" href="./images/favicon.png" />
          )}
          <title>
            {clientId ? clientTitle + " " + title : "걸어본 " + title}
          </title>
        </Helmet>
        {children}
      </div>
    );
  }
);

Page.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
};

export default Page;
