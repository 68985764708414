import React from 'react';
import type { FC, ReactNode } from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuth from '../hooks/useAuth';
import * as Routes from '../routes';

interface NoPhoneGuardProps {
  children?: ReactNode;
}

const NoPhoneGuard: FC<NoPhoneGuardProps> = ({ children }) => {
  const { isAuthenticated, isPhone } = useAuth();

  if (isAuthenticated && isPhone) {
    return <Redirect to={Routes.root} />;
  }

  return <>{children}</>;
};

NoPhoneGuard.propTypes = {
  children: PropTypes.node,
};

export default NoPhoneGuard;
