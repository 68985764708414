import React, { useEffect, useState } from "react";
import type { FC } from "react";
import { makeStyles, Button, Hidden } from "@material-ui/core";
import {
  Badge,
  Grow,
  Paper,
  Popper,
  MenuList,
  ClickAwayListener,
  Drawer,
  Box,
  List,
  ListItemText,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  SelectChangeEvent,
} from "@mui/material";

import { AccountCircle as AccountCircleIcon, Menu as MenuIcon } from "@material-ui/icons";
import NotificationsNoneOutlinedIcon from "@material-ui/icons/NotificationsNoneOutlined";
import { useHistory } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import * as Routes from "../../routes";
import Logo from "../../../public/static/logo-inline.svg";
import { db } from "../../lib/firebase";
import { useTranslation } from "react-i18next";
import isMobile from "../../utils/isMobile";
import Notification from "../../components/Notification";
import LogoImage from "../../../public/static/logo.svg";

const useStyles = makeStyles(({ breakpoints }) => ({
  stickyContainer: {
    position: "fixed",
    width: "100vw",
    backgroundColor: "white",
    zIndex: 1000,
    // overflow: "hidden",
  },
  headerBox: {
    height: 56,
    width: "100%",
    padding: "0px 30px 0px 40px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    boxSizing: "border-box",
    lineHeight: 0,
    borderBottom: "1px solid #E9EEF4",
    [breakpoints.only("xs")]: {
      padding: "0px 20px",
    },
  },
  headerLogo: {
    cursor: "pointer",
  },
  headerNavi: {
    marginLeft: 30,
  },
  headerMenu: {
    marginLeft: "auto",
    display: "flex",
    alignItems: "center",
  },
  naviBtn: {
    padding: 13,
    verticalAlign: "middle",
    cursor: "pointer",
  },
  logoImage: {
    maxWidth: 200,
    maxHeight: 80,
    width: "100%",
  },
  drawerBtn: {
    cursor: "pointer",
  },
  drawerLogo: {
    cursor: "pointer",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: 100,
    marginBottom: 20,
  },
  drawerInnerBtn: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    marginBottom: 20,
  },
  drawerItem: {
    padding: "13px 36px",
    verticalAlign: "middle",
    cursor: "pointer",
  },
  bottomDrawerItem: {
    padding: "13px 36px",
    width: "100%",
    verticalAlign: "middle",
    cursor: "pointer",
  },
  languageSelector: {
    width: 120,
    marginRight: 20,
  },
  drawerNav: {
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
}));

interface HeaderProps {}

const Header: FC<HeaderProps> = props => {
  const classes = useStyles();
  const authContext = useAuth();
  const history = useHistory();
  const [open, setOpen] = React.useState(false);
  const [isBadgeVisible, SetIsBadgeVisible] = React.useState(false);
  const [isOpenDrawer, setOpenDrawer] = React.useState(false);
  const anchorRef = React.useRef(null);
  const { i18n } = useTranslation();
  const [openNotification, setOpenNotification] = React.useState(false);
  const lngs = [
    { value: "ko-KR", title: "한국어" },
    { value: "en-US", title: "English" },
    { value: "ja-JP", title: "日本語" },
    // { value: "zh-CN", title: "中文" },
    // { value: "my-MM", title: "မြန်မာဘာသာ" },
    // { value: "vi-VN", title: "Tiếng Việt" },
  ];

  const handleChange = (event: SelectChangeEvent) => {
    i18n.changeLanguage(event.target.value as string);
  };
  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  const clickMypage = () => {
    setOpen(false);
    history.push(Routes.mypage);
  };

  const clickLogout = () => {
    setOpen(false);
    authContext.logout();
  };

  const clickAlarm = () => {
    // history.push(Routes.notification);
    setOpenNotification(prev => !prev);
  };

  useEffect(() => {
    if (authContext.user) {
      db.collection(`User/${authContext.user.id}/Notification`).onSnapshot(querySnapshot => {
        let isRead = true;
        querySnapshot.forEach(doc => {
          if (doc.data().isNotificationRead === false) {
            isRead = false;
          }
        });
        if (!isRead) {
          SetIsBadgeVisible(false);
        } else {
          SetIsBadgeVisible(true);
        }
      });
    }
  }, []);

  const list = () => (
    <Box
      sx={{ width: 250 }}
      role="presentation"
      onClick={() => setOpenDrawer(false)}
      onKeyDown={() => setOpenDrawer(false)}
      className={classes.drawerNav}
    >
      <List>
        <div
          className={classes.drawerLogo}
          onClick={() => {
            history.push(Routes.root);
          }}
        >
          <img src={Logo} className={classes.logoImage} />
        </div>
        <div
          className={classes.drawerInnerBtn}
          onClick={() => {
            if (isMobile())
              return alert(
                "전시회 생성은 데스크탑에서 최적화 되어있습니다. 데스크탑에서 작업해주세요",
              );
            history.push(Routes.registerExhibition);
          }}
        >
          <Button variant="contained" color="primary">
            {i18n.t("바로 전시회 생성하기")}
          </Button>
        </div>
        {authContext.user && (
          <>
            <div
              className={classes.drawerItem}
              onClick={() => {
                history.push(Routes.manageExhibition);
              }}
            >
              <ListItemText primary={i18n.t("전시회관리")} />
            </div>
          </>
        )}
        <div
          className={classes.drawerItem}
          onClick={() => {
            history.push(Routes.tutorials);
          }}
        >
          <ListItemText primary={i18n.t("영상강좌")} />
        </div>
        <div
          className={classes.drawerItem}
          onClick={() => {
            history.push(Routes.spaces);
          }}
        >
          <ListItemText primary={i18n.t("가상전시공간")} />
        </div>
        <div
          className={classes.drawerItem}
          onClick={() => {
            window.open("http://www.onthewall.io/FAQ", "_blank");
          }}
        >
          <ListItemText primary={i18n.t("자주묻는질문")} />
        </div>
        <div
          className={classes.drawerItem}
          onClick={() => {
            window.open("http://www.onthewall.io/ask_ask", "_blank");
          }}
        >
          <ListItemText primary={i18n.t("문의하기")} />
        </div>
        {authContext.user && (
          <>
            <div
              className={classes.drawerItem}
              onClick={() => {
                history.push(Routes.notification);
              }}
            >
              <ListItemText primary={i18n.t("알림")} />
            </div>
            <div className={classes.drawerItem} onClick={clickMypage}>
              <ListItemText primary={i18n.t("마이페이지")} />
            </div>
            <div className={classes.drawerItem} onClick={clickLogout}>
              <ListItemText primary={i18n.t("로그아웃")} />
            </div>
          </>
        )}
      </List>
      <div className={classes.bottomDrawerItem}>
        <FormControl className={classes.drawerInnerBtn}>
          <InputLabel id="language-selector">Language</InputLabel>
          <Select
            labelId="language-selector"
            value={i18n.language}
            label="Language"
            onChange={handleChange}
            size="small"
            fullWidth
            className={classes.drawerInnerBtn}
          >
            {lngs.map(language => (
              <MenuItem value={language.value}>{language.title}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    </Box>
  );

  return (
    <>
      <header>
        <div className={classes.stickyContainer}>
          <div className={classes.headerBox}>
            <div
              className={classes.headerLogo}
              onClick={() => {
                history.push(Routes.root);
              }}
            >
              {i18n.language === "ko-KR" ? (
                <img src={Logo} className={classes.logoImage} />
              ) : (
                <img src={LogoImage} className={classes.logoImage} />
              )}
            </div>
            <Hidden smDown>
              <div className={classes.headerMenu}>
                {/* <FormControl>
                <InputLabel id="language-selector">Language</InputLabel>
                <Select
                  labelId="language-selector"
                  value={i18n.language}
                  label="Language"
                  onChange={handleChange}
                  size="small"
                  className={classes.languageSelector}
                >
                  {lngs.map((language) => (
                    <MenuItem value={language.value}>{language.title}</MenuItem>
                  ))}
                </Select>
              </FormControl> */}
                {authContext.user ? (
                  <>
                    <span className={classes.naviBtn}>
                      <Badge color="secondary" variant="dot" invisible={isBadgeVisible}>
                        <NotificationsNoneOutlinedIcon onClick={clickAlarm} />
                      </Badge>
                      {/* to-do 알림 내역 리스트 */}
                    </span>
                    <span className={classes.naviBtn}>
                      <AccountCircleIcon
                        ref={anchorRef}
                        aria-controls={open ? "menu-list-grow" : undefined}
                        aria-haspopup="true"
                        onClick={handleToggle}
                      />
                      <Popper
                        open={open}
                        anchorEl={anchorRef.current}
                        role={undefined}
                        transition
                        disablePortal
                      >
                        {({ TransitionProps, placement }) => (
                          <Grow
                            {...TransitionProps}
                            style={{
                              transformOrigin:
                                placement === "bottom" ? "center top" : "center bottom",
                            }}
                          >
                            <Paper>
                              <ClickAwayListener onClickAway={handleClose}>
                                <MenuList id="menu-list-grow" onKeyDown={handleListKeyDown}>
                                  <MenuItem onClick={clickMypage}>{i18n.t("마이페이지")}</MenuItem>
                                  <MenuItem onClick={clickLogout}>{i18n.t("로그아웃")}</MenuItem>
                                </MenuList>
                              </ClickAwayListener>
                            </Paper>
                          </Grow>
                        )}
                      </Popper>
                    </span>
                  </>
                ) : (
                  <>
                    <span
                      className={classes.naviBtn}
                      onClick={() => {
                        history.push(Routes.login);
                      }}
                    >
                      {i18n.t("로그인")}
                    </span>
                    <span
                      className={classes.naviBtn}
                      onClick={() => {
                        history.push(Routes.registerExhibition);
                      }}
                    >
                      <Button variant="contained" color="primary">
                        {i18n.t("전시회 생성하기")}
                      </Button>
                    </span>
                  </>
                )}
              </div>
            </Hidden>
            <Hidden mdUp>
              <MenuIcon onClick={() => setOpenDrawer(true)} className={classes.drawerBtn} />
              <Drawer anchor={"right"} open={isOpenDrawer} onClose={() => setOpenDrawer(false)}>
                {list()}
              </Drawer>
            </Hidden>
          </div>
        </div>
      </header>
      {authContext.user && openNotification && (
        <Notification open={openNotification} setOpen={setOpenNotification} />
      )}
    </>
  );
};

export default Header;
