import React from 'react';
import type { FC, ReactNode } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import Header from './Header';
import Footer from './Footer';

interface SubLayoutProps {
  children?: ReactNode;
}

const useStyles = makeStyles(theme => ({
  root: {
    // backgroundColor: theme.palette.background.default,
    width: '100%',
    display: 'flex',
    flex: '1',
    flexDirection: 'column',
  },
  subSection: {
    backgroundColor: '#fafafa',
    marginTop: 80,
    minHeight: 'calc(100vh - 155px)',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    margin: '0 auto',
  },
}));

const SubLayout: FC<SubLayoutProps> = ({ children }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Header />
      <section className={classes.subSection}>{children}</section>
      <Footer />
    </div>
  );
};

SubLayout.propTypes = {
  children: PropTypes.node,
};

export default SubLayout;
