import React, { useEffect } from "react";
import type { FC, ReactNode } from "react";
import { Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import useAuth from "../hooks/useAuth";
import * as Routes from "../routes";
import { db } from "../lib/firebase";
import * as sessionStorageConst from "../views/api_view/sessionStorageConst";

interface ApiAuthProps {
  children?: ReactNode;
}

const ApiAuth: FC<ApiAuthProps> = ({ children }) => {
  const authContext = useAuth();

  const saveUserState = authContext.saveUserState;
  const uid = window.sessionStorage.getItem(sessionStorageConst.userId);
  const code = window.sessionStorage.getItem(sessionStorageConst.code);

  useEffect(() => {
    if (uid && code) {
      let user = db.collectionGroup("User").where("uid", "==", uid).limit(1);
      user
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            if (doc.data().tempLoginCode === code) {
              saveUserState(null, { ...doc.data(), id: doc.id }, doc.data().phone, "");
            } else {
              alert("페이지를 찾을 수 없습니다. 처음부터 입장해 주세요.");
              window.location.href = "/404.html";
            }
          });
        })
        .catch((error) => {});
    } else {
      alert("페이지를 찾을 수 없습니다. 처음부터 입장해 주세요.");
      window.location.href = "/404.html";
    }
  }, []);

  return <>{children}</>;
};

ApiAuth.propTypes = {
  children: PropTypes.node,
};

export default ApiAuth;
