import React from "react";
import type { FC, ReactNode } from "react";
import { Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import useAuth from "../hooks/useAuth";
import * as Routes from "../routes";
import { useSelector } from "../store";

interface AuthGuardProps {
  children?: ReactNode;
}

const AuthGuard: FC<AuthGuardProps> = ({ children }) => {
  const { isAuthenticated } = useAuth();
  const { id: clientId } = useSelector((state) => state.client);

  if (!isAuthenticated && clientId) {
    return <Redirect to={`/${clientId}${Routes.login}`} />;
  }
  if (!isAuthenticated) {
    return <Redirect to={Routes.login} />;
  }

  return <>{children}</>;
};

AuthGuard.propTypes = {
  children: PropTypes.node,
};

export default AuthGuard;
