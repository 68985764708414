import React, { useEffect } from "react";
import type { FC } from "react";
import { makeStyles, Button } from "@material-ui/core";
import { Badge, Grow, Paper, Popper, MenuItem, MenuList, ClickAwayListener, Drawer, Box, List, ListItemText, Hidden } from "@mui/material";
import { AccountCircle as AccountCircleIcon, Menu as MenuIcon } from "@material-ui/icons";
import NotificationsNoneOutlinedIcon from "@material-ui/icons/NotificationsNoneOutlined";
import { useHistory } from "react-router-dom";
import * as Routes from "../../routes";
import Logo from "../../../public/static/logo-inline.svg";
import { db } from "../../lib/firebase";
import * as sessionStorageConst from "../../views/api_view/sessionStorageConst";

const useStyles = makeStyles(() => ({
  stickyContainer: {
    position: "fixed",
    width: "100%",
    backgroundColor: "white",
    zIndex: 1000,
  },
  headerBox: {
    height: 80,
    width: "100%",
    padding: "0px 40px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    boxSizing: "border-box",
    lineHeight: 0,
    borderBottom: "1px solid #E9EEF4",
  },
  headerLogo: {
    cursor: "pointer",
  },
  headerNavi: {
    marginLeft: 30,
  },
  headerMenu: {
    marginLeft: "auto",
  },
  naviBtn: {
    padding: 13,
    verticalAlign: "middle",
    cursor: "pointer",
  },
  logoImage: {
    width: 150,
  },
  drawerBtn: {
    cursor: "pointer",
  },
  drawerLogo: {
    cursor: "pointer",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: 100,
    marginBottom: 20,
  },
  drawerInnerBtn: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    marginBottom: 20,
  },
  drawerItem: {
    padding: "13px 36px",
    verticalAlign: "middle",
    cursor: "pointer",
  },
}));

interface HeaderProps {}

const Header: FC<HeaderProps> = props => {
  const classes = useStyles();
  const history = useHistory();
  const [open, setOpen] = React.useState(false);
  const [isOpenDrawer, setOpenDrawer] = React.useState(false);
  const [logo, setLogo] = React.useState("");
  const anchorRef = React.useRef(null);

  useEffect(() => {
    const userId = window.sessionStorage.getItem(sessionStorageConst.userId);
    let user = db.collectionGroup("User").where("uid", "==", userId).limit(1);
    user
      .get()
      .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          const clientId = doc.data().clientId;
          let clientRef = db.collection("Client").doc(clientId);
          clientRef
            .get()
            .then(doc => {
              if (doc.exists) {
                setLogo(doc.data().logo);
              }
            })
            .catch(error => {});
        });
      })
      .catch(error => {});
  }, []);

  const list = () => (
    <Box sx={{ width: 250 }} role="presentation" onClick={() => setOpenDrawer(false)} onKeyDown={() => setOpenDrawer(false)}>
      <List>
        <div
          className={classes.drawerLogo}
          onClick={() => {
            history.push(Routes.apiManageExhibition);
          }}
        >
          {logo ? <img src={logo} className={classes.logoImage} /> : "로고위치"}
        </div>
        <div
          className={classes.drawerInnerBtn}
          onClick={() => {
            history.push(Routes.apiRegisterExhibition);
          }}
        >
          <Button variant="contained" color="primary">
            바로 전시회 생성하기
          </Button>
        </div>
        {/* <div
          className={classes.drawerItem}
          onClick={() => {
            history.push(Routes.tutorials);
          }}
        >
          <ListItemText primary="영상강좌" />
        </div>
        <div
          className={classes.drawerItem}
          onClick={() => {
            history.push(Routes.spaces);
          }}
        >
          <ListItemText primary="가상전시공간" />
        </div> */}
        {/* {authContext.user && (
          <div className={classes.drawerItem} onClick={clickLogout}>
            <ListItemText primary="로그아웃" />
          </div>
        )} */}
      </List>
    </Box>
  );

  return (
    <header>
      <div className={classes.stickyContainer}>
        <div className={classes.headerBox}>
          <div
            className={classes.headerLogo}
            onClick={() => {
              history.push(Routes.apiManageExhibition);
            }}
          >
            {logo ? <img src={logo} className={classes.logoImage} /> : "로고위치"}
          </div>
          <Hidden mdDown>
            <div className={classes.headerNavi}>
              {/* <span
                className={classes.naviBtn}
                onClick={() => {
                  history.push(Routes.tutorials);
                }}
              >
                영상강좌
              </span>
              <span
                className={classes.naviBtn}
                onClick={() => {
                  history.push(Routes.spaces);
                }}
              >
                가상전시공간
              </span> */}
            </div>
            {/* <div className={classes.headerMenu}>
              <>
                <span className={classes.naviBtn}>
                  <AccountCircleIcon
                    ref={anchorRef}
                    aria-controls={open ? "menu-list-grow" : undefined}
                    aria-haspopup="true"
                    onClick={handleToggle}
                  />
                  <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
                    {({ TransitionProps, placement }) => (
                      <Grow {...TransitionProps} style={{ transformOrigin: placement === "bottom" ? "center top" : "center bottom" }}>
                        <Paper>
                          <ClickAwayListener onClickAway={handleClose}>
                            <MenuList id="menu-list-grow" onKeyDown={handleListKeyDown}>
                              <MenuItem onClick={clickLogout}>로그아웃</MenuItem>
                            </MenuList>
                          </ClickAwayListener>
                        </Paper>
                      </Grow>
                    )}
                  </Popper>
                </span>
              </>
            </div> */}
          </Hidden>
          <Hidden mdUp>
            <MenuIcon onClick={() => setOpenDrawer(true)} className={classes.drawerBtn} />
            <Drawer anchor={"right"} open={isOpenDrawer} onClose={() => setOpenDrawer(false)}>
              {list()}
            </Drawer>
          </Hidden>
        </div>
      </div>
    </header>
  );
};

export default Header;
