import axios from "axios";
import { useSelector } from "../store";

const sendApproveEmail = (userData, clientId, clientEmail) => {
  axios
    .post(
      "https://us-central1-gd-virtual-staging.cloudfunctions.net/sendApproveEmail",
      {
        title: "가입 승인 요청이 들어왔습니다.",
        userName: userData.userName ? userData.userName : "", // 사용자 이름
        userEmail: userData.email ? userData.email : "", // 사용자 이메일
        toEmail: clientEmail, // 관리자 이메일
        signUpMessage: userData.signUpMessage ? userData.signUpMessage : "", // 신청 메세지
        redirectURL: `https://cloud.onthewall.io/${clientId}/admin/grant-user`,
      }
    )
    .then((result) => {
      console.log(clientEmail + " 이메일 전송 성공");
    })
    .catch((error) => {
      console.log("이메일 전송 실패", error);
    });
};

export default sendApproveEmail;
